import type { IAnyStateTreeNode, Instance } from "@gadgetinc/mobx-quick-tree";
import { useStyletron } from "baseui";
import type { Override } from "baseui/overrides";
import { defaultTo, merge, omit } from "lodash";
import { observer } from "mobx-react-lite";
import React, { forwardRef, type ReactNode } from "react";
// eslint-disable-next-line no-restricted-imports
import type { ButtonOverrides } from "baseui/button";
import { paddingUtil } from "../../utils";
import type { PLACEMENT, ToolTipLabelSize } from "../ToolTip";
import { ToolTip } from "../ToolTip";
import { useWidgets } from "../contexts";
import type { ButtonProps } from "./Button";
import { Button, ButtonHoverSelector, ButtonKind, ButtonSize } from "./Button";
import { SelectableButton } from "./SelectableButton";

export type IconButtonProps = {
  tooltipIgnoreBoundary?: boolean;
  tooltipEnabled?: boolean;
  tooltipPlacement?: PLACEMENT[keyof PLACEMENT];
  label: ReactNode;
  labelSize?: ToolTipLabelSize;
  selectable?: boolean;
  node?: Instance<IAnyStateTreeNode>;
  subNodeKey?: string;
  overrides?: ButtonOverrides & { Wrapper?: Exclude<Override<any>, React.ComponentType<any>> };
  dataTestId?: string;
  shortcutKeys?: string[];
} & ButtonProps;

export const IconButton = observer(
  forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
    const [css, $theme] = useStyletron();

    const { onClick } = props;
    let kind = props.kind ?? ButtonKind.tertiary;
    if (kind == "minimal") kind = ButtonKind.tertiary; // for icon buttons, minimal and tertiary are the same thing

    const { disableButtons } = useWidgets();
    const disabled = props.disabled ?? disableButtons?.(props);

    const overrides = merge(
      {
        BaseButton: {
          style: {
            ...(props.size ? {} : paddingUtil("4px")),
            [ButtonHoverSelector]: {
              textDecoration: "none",
            },
            ":hover:active:not(:disabled)": {
              textDecoration: "none",
            },
          },
        },
      },
      props.overrides,
      { Wrapper: undefined }
    );

    if (kind == "tertiary") {
      merge(overrides.BaseButton.style, {
        color: $theme.colors.contentPrimary,
        backgroundColor: "transparent",
        ":disabled": {
          backgroundColor: $theme.colors.alpha50,
          color: $theme.colors.alpha300,
        },
        ":active:not(:disabled)": {
          backgroundColor: $theme.colors.alpha100,
          color: $theme.colors.contentPrimary,
          boxShadow: $theme.lighting.shadowButtonInset,
        },
        [ButtonHoverSelector]: { backgroundColor: $theme.colors.alpha100, color: $theme.colors.contentPrimary },
        ":focus-visible": {
          color: $theme.colors.contentPrimary,
        },
      });
    }

    const buttonProps: ButtonProps = {
      onClick,
      overrides,
      kind: props.kind ?? ButtonKind.tertiary,
      ref,
      disabled,
      size: props.size ?? ButtonSize.compact,
      ...omit(props, [
        "tooltipIgnoreBoundary",
        "tooltipPlacement",
        "node",
        "subNodeKey",
        "onClick",
        "selectable",
        "tooltipEnabled",
        "dataTestId",
        "overrides",
        "shortcutKeys",
      ]),
    };

    let button = props.selectable ? (
      <SelectableButton
        data-testid={props.dataTestId}
        node={props.node}
        subNodeKey={props.subNodeKey}
        onAction={() => {
          const handler = props.onClick as any as () => void;
          handler();
        }}
      >
        {(forwardProps) => <Button {...forwardProps} {...buttonProps} />}
      </SelectableButton>
    ) : (
      <Button data-testid={props.dataTestId} {...buttonProps} />
    );

    if (defaultTo(props.tooltipEnabled, true)) {
      button = (
        <ToolTip
          label={props.label}
          labelSize={props.labelSize}
          ignoreBoundary={props.tooltipIgnoreBoundary}
          placement={props.tooltipPlacement}
          shortcutKeys={props.shortcutKeys}
          overrides={{ Outer: props.overrides?.Wrapper as any }}
        >
          {button}
        </ToolTip>
      );
    }

    return button;
  })
);
